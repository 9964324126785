
import React, { useState } from "react";
import 'powerbi-report-authoring';
import LoginForm from "./components/LoginForm";

import './App.css';
import AdminPage from "./components/AdminPanel";

function App() {
  
  const logout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  }

  const [adminPageOpened, setAdminPageOpened] = useState(false);

  const header = 
    <div className="header">
      <img src="https://spatiallyhealth.com/wp-content/uploads/2021/02/site-logo.svg" alt="spatially health logo" width={"10.5%"}></img>
      {localStorage.getItem('token') && <button onClick={logout} className='tablink'> Logout </button>}
      {localStorage.getItem('token') && localStorage.getItem('permissions').includes('admin') &&
        <button onClick={() => { setAdminPageOpened(true)}} className='tablink'> Admin Panel </button>}
    </div>;

  const loginComponent = <LoginForm />;

  const footer =
    <div className="footer">
      <p>Spatially Health Portal</p>
      <label className="separator-pipe">|</label>
      <p>Copyright &copy; 2023 Spatially Health</p>
      <div className="clearfix"/>
    </div>;



  return (
    <div className="container">
      {header}
      {loginComponent}
      <div id="myModal" className="modal" style={adminPageOpened ? { "display": 'block' } : { "display": 'none' }}>
      
        <div className="modal-content">
          <div className="modal-header">
            <button onClick={() => { setAdminPageOpened(false) }} className="modal-close"> x </button>
            <h3>Admin Panel</h3>
          </div>
          <div className="modal-body">
            <AdminPage/>
          </div>

        </div>

      </div>
      {footer}
    </div>
  );
}

export default App;

