import React, { useState } from "react";
import axios from 'axios';

import "../App.css";
import { flaskAPI } from "../api/flask-app";

function AdminPage(props) {
    // React States
    const [userErrorMessages, setUserErrorMessages] = useState({});
    const [tenantErrorMessages, setTenantErrorMessages] = useState({});

    const [userSuccessMessages, setUserSuccessMessages] = useState({}); 
    const [tenantSuccessMessages, setTenantSuccessMessages] = useState({});

    const [tenants, setTenants] = useState([]);

    async function fetchTenants() {
        if (tenants.length === 0) {
            const fetched_tenants = await axios.get(flaskAPI.getTenants, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                }
            });
            setTenants(fetched_tenants.data);
        }
    }
    
    const handleUserCreate = async (event) => {
        //Prevent page reload
        event.preventDefault();

        setUserErrorMessages({ message: "" });
        setUserSuccessMessages({ message: "" });

        const { email, pass, tenant_id } = document.forms[0];

        try {
            const newUser = await axios.post(
                flaskAPI.createUser, 
                {
                    email: email.value,
                    password: pass.value,
                    role: 'viewer',
                    tenant_id: tenant_id.value
                }, 
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                })
            if (newUser.data) {
                setUserSuccessMessages({ message: "User created successfully" });
            }

    
        } 
        catch (error) {
            console.log("Error",error);
            setUserErrorMessages({ message: error.response.data.message });

        }
    };

    const handleTenantCreate = async (event) => {
        //Prevent page reload
        event.preventDefault();

        const { tenant_name, tenant_host, dataset_id, report_id } = document.forms[1];

        setTenantErrorMessages({ message: "" });
        setTenantSuccessMessages({ message: "" });

        try {
            const newTenant = await axios.post(
                flaskAPI.createTenant,
                {
                    name: tenant_name.value,
                    org_domain: tenant_host.value,
                    dataset_id: dataset_id.value,
                    reports: [report_id.value]
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                })
            if (newTenant.data) {
                setTenantSuccessMessages({ message: "Tenant created successfully" });
            }


        }
        catch (error) {
            console.log("Error", error);
            setTenantErrorMessages({ message: error.response.data.message });
        }

    };

    const downloadFile = ({ data, fileName, fileType }) => {
        // Create a blob with the data we want to download as a file
        const blob = new Blob([data], { type: fileType })
        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getUserUsage = async (event, tenant_id, date) => {  
        event.preventDefault();
        try {
            const userUsage = await axios.get(`${flaskAPI.userUsage}/${tenant_id}/${date}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
                responseType: 'blob'
            });

            return downloadFile({
                data: userUsage.data,
                fileName: `user_usage_${tenant_id}_${date}.csv`,
                fileType: 'text/csv'
            });
            
        } catch (error) {
            console.log("Error", error);
        }
    }

    
    // Generate JSX code for error message
    const renderErrorMessage = (tab) => <div className="error">{tab === 'user' ? userErrorMessages.message : tenantErrorMessages.message}</div>;
    const renderSuccessMessage = (tab) => <div className="success">{tab === 'user' ? userSuccessMessages.message : tenantSuccessMessages.message}</div>;

    const openAdminTab = (event, tabName) => {
        // Declare all variables
        let i, tabcontent, tablinks;

        // Get all elements with className="tabcontent" and hide them
        tabcontent = document.getElementsByClassName("tabcontent");
        console.log(tabcontent);
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Get all elements with className="tablinks" and remove the className "active"
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        // Show the current tab, and add an "active" className to the button that opened the tab
        document.getElementById(tabName).style.display = "block";
        event.currentTarget.className += " active";
    }
     

    // JSX code for login form
    const renderForm = (
        <div>
            <div className="tab">
                <button className="tablinks" onClick={(e) => { openAdminTab(e, 'usage') }}>User Usage</button>
                <button className="tablinks active" onClick={(e) => { openAdminTab(e, 'user') }}>Create User</button>
                <button className="tablinks" onClick={(e) => { openAdminTab(e, 'tenant') }}>Create Tenant</button>
            </div>

            <div id="user" className="tabcontent" style={{ "display": "block" }}>
                <div className="admin-form">
                    <form onSubmit={handleUserCreate} >
                        <div className="input-container">
                            <label><b>Email </b></label>
                            <input type="text" name="email" required />
                        </div>
                        <div className="input-container">
                            <label><b>Password </b></label>
                            <input type="password" name="pass" required />
                        </div>
                        <div className="input-container">
                            <label><b>Tenant </b></label>
                            <select name='tenant_id' onClick={()=>{fetchTenants()}} defaultValue="">
                                <option value="" disabled key='0'>Select a tenant </option>
                                {tenants.map((tenant) => { return <option key={tenant.id} value={tenant.id}>{tenant.name}</option> })}
                            </select>
                        </div>
                        {renderErrorMessage('user')}
                        {renderSuccessMessage('user')}
                        <div className="button-container">
                            <input type="submit" value="Create User" />
                        </div>
                    </form>
                </div>
            </div>

            <div id="tenant" className="tabcontent">
                <div className="admin-form">
                    <form onSubmit={handleTenantCreate} >
                        <div className="input-container">
                            <label><b> Tenant Name </b></label>
                            <input type="text" name="tenant_name" required />
                        </div>
                        <div className="input-container">
                            <label><b> Host Origin</b></label>
                            <input type="text" name="tenant_host" required />
                        </div>
                        <div className="input-container">
                            <label><b> Dataset ID</b></label>
                            <input type="text" name="dataset_id" required />
                        </div>
                        <div className="input-container">
                            <label><b> Report ID</b></label>
                            <input type="text" name="report_id" required />
                        </div>
                        {renderErrorMessage('tenant')}
                        {renderSuccessMessage('tenant')}
                        <div className="button-container">
                            <input type="submit" value="Create Tenant" />
                        </div>
                    </form>
                </div>
            </div>

            <div id="usage" className="tabcontent">
                <div className="admin-form">
                    <form onSubmit={(e) => { getUserUsage(e, document.forms[2].tenant_id.value, document.forms[2].date.value) }}> 
                        <div className="input-container">
                            <label><b> Date </b></label>
                            <input type="month" name="date" required defaultValue={new Date().toISOString().substr(0, 7)} />
                        </div>
                        <div className="input-container">
                            <label><b>Tenant </b></label>
                            <select name='tenant_id' onClick={() => { fetchTenants() }} defaultValue="">
                                <option value="" disabled key='0'>Select a tenant </option>
                                {tenants.map((tenant) => { return <option key={tenant.id} value={tenant.id}>{tenant.name}</option> })}
                            </select>
                        </div>
                        <div className="button-container">
                            <input type="submit" value="Download" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
                  
    );

    return renderForm;
}

export default AdminPage;