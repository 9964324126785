import React, { useState } from "react";
import ReportView from "./ReportView";
import axios from 'axios';

import "../App.css";
import { flaskAPI } from "../api/flask-app";

function LoginForm(props) {
    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(localStorage.getItem('token') ? true : false);

    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];

        try {
            const userData = await axios.post(
                flaskAPI.login, 
                {
                    username: uname.value,
                    password: pass.value
                }, 
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
                        'Content-Type': 'application/json',
                    }
                })
            const {data} = userData;

            if (data.token) {
                setIsSubmitted(true);
                localStorage.setItem('token', data.token);
                localStorage.setItem('permissions', data.permissions);
                localStorage.setItem('username', data.username);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('tenant_id', data.tenant_id);
                window.location.reload();
            }
        } 
        catch (error) {
            console.log("Error",error);
            setErrorMessages({ message: error.response.data.message });

        }

    };

    // Generate JSX code for error message
    const renderErrorMessage = () => <div className="error">{errorMessages.message}</div>;
     

    // JSX code for login form
    const renderForm = (
        <div className="login-container">
        <div className="login-form">
            <form onSubmit={handleSubmit} >
                <img src="https://spatiallyhealth.com/wp-content/uploads/2021/02/SpatiallyHealth_Color.svg?fsum=28387d934b40" alt="spatially health logo" width={"40%"}></img>
                <div className="login-input-container">
                    <label> <b>Username</b> </label>
                    <input type="text" name="uname" required />
                </div>
    
                <div className="login-input-container">
                    <label><b>Password</b> </label>
                    <input type="password" name="pass" required />
                </div>
                <div className="button-container">
                    <input type="submit" value="Log In"/>
                </div>
                {renderErrorMessage()}
            </form>
        </div>
        </div>
        
    );

    return isSubmitted ? <ReportView /> : renderForm;

}

export default LoginForm;