const baseUrl = process.env.REACT_APP_API_URL;

export const flaskAPI = {
    'login': `${baseUrl}/users/auth/login`,
    'createUser': `${baseUrl}/users/create`,
    'createTenant': `${baseUrl}/tenants/create`,
    'getTenants': `${baseUrl}/tenants`,
    'embedToken': `${baseUrl}/reports/embedtoken`,
    'userUsage': `${baseUrl}/users/usage`
}

