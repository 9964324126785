
import React, { useState, useEffect } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import 'powerbi-report-authoring';

import "../App.css"; 
import axios from 'axios';
import { flaskAPI } from '../api/flask-app';

// Root Component to demonstrate usage of embedded component
function ReportView() {

    // PowerBI Report object (to be received via callback)
    const [report, setReport] = useState();

    // Track Report embedding status
    const [isEmbedded, setIsEmbedded] = useState(false);

    // Overall status message of embedding
    const [displayMessage, setMessage] = useState(`Loading your report...`);

    const [mode, setMode] = useState('view');


    // CSS Class to be passed to the embedded component
    const reportClass = 'report-container';

    // Pass the basic embed configurations to the embedded component to bootstrap the report on first load
    // Values for properties like embedUrl, accessToken and settings will be set on click of button

    // Read embed URL
    const embedUrl = (reportId) => `https://app.powerbi.com/reportEmbed?reportId=${reportId}&language=en&formatLocale=en-AU`;


    const [sampleReportConfig, setReportConfig] = useState({
        type: 'report',
        embedUrl: '',
        id: '',
        tokenType: models.TokenType.Embed,
        accessToken: ''
    });

    const handleUserEvent = async (eventType, component, page, visualType = '') => {

        try {
            await axios.post(
                flaskAPI.userUsage,
                {
                    userId: localStorage.getItem('user_id'),
                    tenantId: localStorage.getItem('tenant_id'),
                    username: localStorage.getItem('username'),
                    interactionType: eventType, 
                    visualType: visualType,
                    componentName: component,
                    reportId: sampleReportConfig.id,
                    pageId: page.id,
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Methods': 'POST, PUT, PATCH, GET, DELETE, OPTIONS',
                        'Content-Type': 'application/json',
                    }
                })
        }
        catch (error) {
            console.log("Error", error);
        }

    };

    useEffect(() => {
        if (!report) {
            embedReport();
        }
    });


    useEffect( () => {
        if (report) {
            report.setComponentTitle('Embedded Report');
            setMode('view');  
            
            let page = {id: '', name: ''};

            report.on('buttonClicked', (data) => {
                console.log('Button clicked:', data.detail);
                let buttonName = data.detail.title ? data.detail.title : data.detail.id;
                handleUserEvent('buttonClicked', buttonName, page);
            });

            report.on('pageChanged', (data) => {
                console.log('Page changed:', data.detail.newPage.displayName);
                page = {id: data.detail.newPage.name, name: data.detail.newPage.displayName};
                handleUserEvent('pageChanged', data.detail.newPage.displayName, page);
            });

            report.on('dataSelected', (data) => {
                console.log('Data selected:', data.detail.visual);
                handleUserEvent('dataSelected', data.detail.visual.title, page, data.detail.visual.type);
            });
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    /**
     * Embeds report
     *
     * @returns Promise<void>
     */
    const embedReport = async () => {
        console.log('Embed Report clicked');

        // Get the embed config from the service
        const reportConfigResponse = await axios.post(
            flaskAPI.embedToken,
            {},
            { headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            } }
            )

        if (reportConfigResponse === null) {
            return;
        }

        if (!reportConfigResponse.status === 200) {
            console.error(`Failed to fetch config for report. Status: ${reportConfigResponse.status} ${reportConfigResponse.statusText}`);
            return;
        }

        const reportConfig = reportConfigResponse.data;

        // Update the reportConfig to embed the PowerBI report
        setReportConfig({
            ...sampleReportConfig,
            accessToken: reportConfig['token'],
            id: reportConfig['reportId']['id'],
            embedUrl: embedUrl(reportConfig['reportId']['id']),
            permissions: models.Permissions.All,
            mode: models.ViewMode.Edit,
        });

        setIsEmbedded(true);

        // Update the display message
        setMessage('Use the buttons above to interact with the report using Power BI Client APIs.');
    };


    const switchReportMode = () => {
        if (mode === 'edit') {
            report.switchMode('view');
            setMode('view');
        } else {
            report.switchMode('edit');
            setMode('edit');
        }
        console.log(!localStorage.getItem('permissions').includes('write'));
    }


    const controlButtons =
        isEmbedded ?
            <>
                <button onClick={() => { report.fullscreen() }}> Fullscreen </button>
                <button onClick={() => { switchReportMode() }} hidden={!localStorage.getItem('permissions').includes('write')} > Edit </button>
            </>
            :
            <>
                <label className="display-message position"> {displayMessage} </label>
            </>;

    const reportComponent =
        <PowerBIEmbed
            embedConfig={sampleReportConfig}
            cssClassName={reportClass}
            getEmbeddedComponent={(embedObject) => {
                console.log(`Embedded object of type "${embedObject.embedtype}" received`);
                setReport(embedObject);
            }}
        />;



    return (
            <div className="controls">
                {isEmbedded ? reportComponent : null}
                {controlButtons}
            </div>
    );
}

export default ReportView;

